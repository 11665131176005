
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import theme from '../theme';
import { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import DocumentCard from '../components/DocumentCard';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useAIsearchQuery, useSearchQuery } from '../lib/api/GraphAPI';
import {
  setContextSearch,
  setExactSearch,
  setSearchMode,
  setSearchQuery,
} from '../lib/state/slices/ProjectStateSlice';
import { SearchMode } from '../lib/state/ViewTypes';
import sortNodes from '../lib/Sort';

export default function ResearchPage() {

  const dispatch = useAppDispatch();
  const searchMode = useAppSelector((state) => state.viewReducer.searchMode);
  const searchQuery = useAppSelector((state) => state.viewReducer.searchQuery);
  const projectState = useAppSelector((state) => state.viewReducer);

  const { data: dataAI, isLoading: isLoadingAI, isSuccess: isSuccessAI } = useAIsearchQuery(searchQuery);
  const { data, isLoading, isSuccess } = useSearchQuery(searchQuery);

  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [sortOrder, setSortOrder] = useState<"default" | "date" | "relevance">("default")
  
  let nodes = searchMode === SearchMode.EXACT ? projectState.exactSearch : projectState.contextualSearch;

  // Stores relevant results in state
  useEffect(() => {
    if (!searchQuery || searchQuery === '') return;

    if (searchMode === SearchMode.EXACT) {
      if (isSuccess && data) {
        dispatch(setExactSearch(data));
      }
    } else {
      if (isSuccessAI && dataAI) {
        dispatch(setContextSearch(dataAI.nodes));
      }
    }
  }, [searchQuery, isSuccess, isSuccessAI, data, dataAI]);

  // sets DebouncedQuery equal to value in ProjectState when it loads in
  useEffect(() => {
    setDebouncedQuery(searchQuery);
  }, [searchQuery]);

  // Debounced query logic
  useEffect(() => {
    const handler = setTimeout(() => dispatch(setSearchQuery(debouncedQuery)), 2000);
    return () => clearTimeout(handler);
  }, [debouncedQuery]);

  return (
    <Stack direction="column" rowGap={4} padding={4} width="100%" height="100%">
      <Stack
        height="247px"
        width="100%"
        padding={5}
        borderRadius="0.375rem"
        background="linear-gradient(0deg, #9F7AEA 0.51%,rgb(222, 201, 251) 97%)"
        backgroundColor={theme.colors.purple[200]}
      >
        <Stack direction="column" minWidth="100%" alignItems="center" alignSelf="center">
          <Stack direction="row" maxWidth="50%" minWidth="50%">
            <Tooltip
              label={
                <Stack>
                  <Text>This is your active project</Text>
                  <Text>It is saved every 5 seconds</Text>
                </Stack>
              }
              placement="right"
              openDelay={500}
            >
              <Stack direction="row">
                <Text fontWeight={700} fontSize="xx-large" cursor="pointer">
                  Project:&nbsp;
                </Text>
                <Text
                  fontWeight={700}
                  fontSize="xx-large"
                  color={theme.colors.purple[500]}
                  fontStyle="italic"
                  cursor="pointer"
                >
                  {projectState.name}
                </Text>
              </Stack>
            </Tooltip>
          </Stack>

          <InputGroup maxWidth="50%" minWidth="50%">
            <InputLeftElement>
              <Search />
            </InputLeftElement>
            <Input
              placeholder="Search for a case..."
              variant="filled"
              background="white"
              _focus={{ bg: 'white' }}
              value={debouncedQuery}
              onChange={(e) => setDebouncedQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(setSearchQuery(debouncedQuery));
                }
              }}
            ></Input>
          </InputGroup>

          <Stack direction="row" justifyContent="space-between" maxWidth="50%" minWidth="50%">
            <Tooltip label="The search type" placement="right" openDelay={1000}>
              <ButtonGroup isAttached variant="solid" size="sm" borderRadius="0.375rem">
                <Button
                  onClick={() => dispatch(setSearchMode(SearchMode.CONTEXT))}
                  colorScheme={searchMode === SearchMode.CONTEXT ? 'purple' : 'gray'}
                >
                  Contextual
                </Button>
                <Button
                  onClick={() => dispatch(setSearchMode(SearchMode.EXACT))}
                  colorScheme={searchMode === SearchMode.EXACT ? 'purple' : 'gray'}
                >
                  Exact Match
                </Button>
              </ButtonGroup>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        height="100%"
        width="100%"
        borderRadius="0.375rem"
        backgroundColor={theme.colors.gray[100]}
        padding={4}
        overflowY="auto"
        overflowX="hidden"
        direction="column"
        rowGap={4}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text fontWeight={600} color={theme.colors.purple[700]} fontSize="large">
            {!nodes
              ? 'Loading...'
              : // : searchQuery === ''
              // ? 'Waiting for search input'
              nodes.length === 0
                ? 'No results found'
                : 'Results: ' + nodes.length}
          </Text>

          <Stack direction="row">
            {/* <IconButton
              variant="solid"
              background="white"
              size="sm"
              width="fit-content"
              borderRadius="0.375rem"
              aria-label=""
            >
              <Filter />
            </IconButton> */}
            <InputGroup width="fit-content">
              <Select
                variant="solid"
                background="white"
                size="sm"
                width="fit-content"
                borderRadius="0.375rem"
                _hover={{ bg: theme.colors.gray[200], transition: 'background-color 0.2s' }}
                _active={{ bg: theme.colors.gray[300], transition: 'background-color 0.2s' }}
                transition="background-color 0.2s"
                onChange={(e) => setSortOrder(e.target.value as 'default' | 'date' | 'relevance')}
                value={sortOrder}
              >
                <option value="default">Best Match</option>
                <option value="date">Sort by date (new-old)</option>
                <option value="relevance">Sort by importance</option>
              </Select>
            </InputGroup>
          </Stack>
        </Stack>

        <Divider
          style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
          color="darkgray"
          width={1}
          orientation="horizontal"
        />

        <Grid
          padding={4}
          style={{
            height: '100%',
            backgroundColor: theme.colors.gray[100],
            borderRadius: '10px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            justifyItems: 'center',
          }}
          templateColumns={'repeat(auto-fit, minmax(380px, 1fr))'}
          gap={4}
        >
          {sortNodes(nodes, sortOrder).map((node) => (
            <DocumentCard key={node.properties.id} id={node.properties.id} />
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
