import {
  Button,
  Card,
  CardBody,
  CardFooter, Flex, HStack,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  theme,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useGetNodeQuery, useGetSummaryQuery } from '../lib/api/GraphAPI';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { motion } from 'framer-motion';
import { ImportanceNames } from '../lib/api/APITypes';
import { getDefaultGraphView } from '../lib/GraphFactory';
import { articleTooltips, importanceLevelTooltips, protocolTooltips } from '../strings/tooltips';
import { addNewGraphView, saveDoc } from '../lib/state/slices/ProjectStateSlice';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ExternalLink, Folder, Maximize2, MoreVertical, PenTool, Save, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { ContextMenuButton } from './ContextMenu';
import { AISparcles } from './Icons';

export default function DocumentCard(props: {
  id: string;
  nodeId?: string;
  collapsedState?: 'open' | 'collapsed';
  onClose?: () => void;
  style?: CSSProperties;
}) {
  const [collapsedState, setCollapsedState] = useState<'open' | 'collapsed'>(
    'collapsed',
  );
  const disclosure = useDisclosure();
  const initialFocusRef = useRef(null);
  const {
    data: nodeData,
    error: nodeError,
    isLoading: nodeIsLoading,
    isSuccess: nodeIsSuccess,
  } = useGetNodeQuery(props.id);

  const { isOpen: isZoteroModalOpen, onOpen: onZoteroModalOpen, onClose: onZoteroModalClose } = useDisclosure()
  const folders = useAppSelector((state) => state.viewReducer.folders);


  useEffect(() => {
    if (isZoteroModalOpen && nodeData) {
      console.log("Adding citation metadata");
      const head = document.head;


      head.insertAdjacentHTML('beforeend',
        `<meta name="citation_title" content="${nodeData.properties.label}"/>` +
        `<meta name="DC.type" content="case" />` +
        `<meta name="citation_date" content="${new Date(nodeData.properties.judgementdate!).toDateString()}"/>` +
        `<meta name="citation_language" content="en"/>` +
        `<meta name="citation_public_url" content="${nodeData.properties.url || ''}"/>`
      );
      document.dispatchEvent(new CustomEvent('ZoteroItemUpdated', { bubbles: true, cancelable: true }));
    }

    if (!isZoteroModalOpen) {
      const head = document.head;
      const metaTags = head.querySelectorAll('meta[name^="citation"], meta[name^="DC"]');
      metaTags.forEach(meta => head.removeChild(meta));
      document.dispatchEvent(new CustomEvent('ZoteroItemUpdated', { bubbles: true, cancelable: true }));
    };
  }, [isZoteroModalOpen, nodeData]);

  const { data: summary, error: summaryError, isLoading: summaryIsLoading, isSuccess: summaryIsSuccess } = useGetSummaryQuery(props.id);
  const toast = useToast();

  const dispatch = useAppDispatch();

  function openInGraph() {
    let newGraphView = getDefaultGraphView(props.id);
    newGraphView.name = nodeData
      ? nodeData.properties.label!!.replace('CASE OF', '')
      : 'Graph ' + props.id;
    dispatch(addNewGraphView(newGraphView));
    navigate('/graph/' + newGraphView.id);
  }
  const navigate = useNavigate();
  const textVariants = {
    halfOpen: {
      display: 'block',
      overflowY: 'hidden',
      maxHeight: '150px',
    },
    open: {
      display: 'block',
      overflowY: 'auto',
      maxHeight: '230px',
    },
    collapsed: {
      display: 'none',
    },
  };


  return (
    <motion.div
      initial={{ opacity: 0, height: '0', x: -100 }}
      animate={{ opacity: 1, height: 'auto', x: 0 }}
      style={{ ...props.style, zIndex: 100 }}
    >
      <Card width={'390px'} style={{ boxShadow: '0px 4px 20px -4px #00000080' }}>
        <CardBody>
          <Stack spacing="3">
            <Stack direction={'row'} justifyContent={'space-between'}>
              {nodeIsLoading ? (
                <Spinner />
              ) : (
                <Heading size="md" whiteSpace={'normal'}>
                  {nodeData ? nodeData.properties.label : 'Something went wrong'}
                </Heading>
              )}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Icon
                  onClick={() => {
                    if (collapsedState === 'open')
                      setCollapsedState('collapsed');
                    else if (collapsedState === 'collapsed') setCollapsedState('open');
                  }}
                  cursor={'pointer'}
                  boxSize={5}
                  as={Maximize2}
                  padding={'2px'}
                  color={theme.colors.purple[700]}
                />
                <Popover
                  initialFocusRef={initialFocusRef}
                  isOpen={disclosure.isOpen}
                  onOpen={disclosure.onOpen}
                  placement="right"
                  offset={[0, 0]}
                >
                  <PopoverTrigger>
                    <Icon
                      boxSize={5}
                      padding={'2px'}
                      color={theme.colors.purple[700]}
                      cursor={'pointer'}
                      as={MoreVertical}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent width="max-content" padding={0}>
                      <ContextMenu onClose={disclosure.onClose}>
                        <ContextMenuButton icon={ExternalLink} text={'Open Document'} onClick={() => {
                          window.open(nodeData?.properties.url, '_blank')?.focus();
                        }} />
                        <ContextMenuButton
                          icon={Save}
                          text="Save to Folder"
                          onClick={() => { }}
                          submenu={
                            <ContextMenu onClose={() => { }}>
                              {folders
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((folder, index) => (
                                  <ContextMenuButton
                                    key={folder.id}
                                    icon={Folder}
                                    text={folder.name}
                                    onClick={() => {
                                      dispatch(saveDoc({ folderId: folder.id, nodeId: props.id }));
                                      toast({
                                        title: 'Document saved',
                                        description: 'The document has been saved to ' + folder.name,
                                        status: 'info',
                                        duration: 3000,
                                        isClosable: true,
                                      });
                                    }}
                                  />
                                ))}
                            </ContextMenu>
                          }
                        />

                        <ContextMenuButton icon={PenTool} text={'Cite with Zotero'} onClick={() => {
                          disclosure.onClose();
                          onZoteroModalOpen();
                        }} />
                      </ContextMenu>
                    </PopoverContent>
                  </Portal>
                </Popover>
                {props.onClose ? (
                  <Icon
                    onClick={props.onClose}
                    cursor={'pointer'}
                    boxSize={5}
                    as={X}
                    color={theme.colors.red[800]}
                    marginLeft={'10px'}
                  />
                ) : null}
              </div>
            </Stack>
            <HStack spacing={1} flexWrap={'wrap'}>
              {nodeData?.properties.importance ? (
                <Tooltip label={importanceLevelTooltips[nodeData?.properties.importance]}>
                  <Tag variant={'solid'} bgColor={theme.colors.blue[900]}>
                    {ImportanceNames[nodeData?.properties.importance]}
                  </Tag>
                </Tooltip>
              ) : null}
              {nodeData?.properties.violation
                ? nodeData?.properties.violation.split(';').map((violation, index) => {
                  if (violation.startsWith('p')) {
                    return (
                      <Tooltip label={protocolTooltips(violation)} key={violation + index}>
                        <Tag bgColor={theme.colors.red[600]} textColor={'white'}>
                          Protocol {violation}
                        </Tag>
                      </Tooltip>
                    );
                  }
                  return (
                    <Tooltip
                      label={articleTooltips[parseInt(violation) - 1]}
                      key={violation + index}
                    >
                      <Tag bgColor={theme.colors.red[600]} textColor={'white'}>
                        Article {violation}
                      </Tag>
                    </Tooltip>
                  );
                })
                : null}
              {nodeData?.properties.nonviolation
                ? nodeData?.properties.nonviolation.split(';').map((nonviolation, index) => {
                  if (nonviolation.startsWith('p')) {
                    return (
                      <Tooltip label={protocolTooltips(nonviolation)} key={nonviolation + index}>
                        <Tag bgColor={theme.colors.green[600]} textColor={'white'}>
                          Protocol {nonviolation}
                        </Tag>
                      </Tooltip>
                    );
                  }
                  return (
                    <Tooltip
                      label={articleTooltips[parseInt(nonviolation) - 1]}
                      key={nonviolation + index}
                    >
                      <Tag bgColor={theme.colors.green[600]} textColor={'white'}>
                        Article {nonviolation}
                      </Tag>
                    </Tooltip>
                  );
                })
                : null}
              {nodeData?.properties.keywords
                ? nodeData?.properties.keywords.map((keyword, index) => (
                  <Tooltip label={keyword} key={keyword + index}>
                    <Tag bgColor={theme.colors.yellow[500]} textColor={'white'}>
                      {keyword.length > 10 ? keyword.substring(0, 10) + '...' : keyword}{' '}
                    </Tag>
                  </Tooltip>
                ))
                : null}
            </HStack>
            {nodeData ? (
              <HStack spacing={2}>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  Date: {new Date(nodeData.properties.judgementdate!).toLocaleDateString('de-DE')}{' '}
                </Text>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  |
                </Text>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  Appno:{' '}
                  {
                    <Tooltip label={nodeData.properties.appno.replaceAll(';', '  ')}>
                      {nodeData.properties.appno.length > 10
                        ? nodeData.properties.appno.substring(0, 10) + '...'
                        : nodeData.properties.appno}
                    </Tooltip>
                  }
                </Text>
              </HStack>
            ) : (
              <Spinner />
            )}
            {nodeIsLoading ? (
              <Spinner colorScheme="purple" />
            ) : collapsedState === 'open' ? (
              <div >   
                <Flex direction={'row'} alignItems={'center'}>
                  <Icon as={AISparcles} size={14}/>
                  <Text
                fontWeight={600}
                fontSize="14px"
                color={theme.colors.gray[700]}
              >
                AI Summary{' '}
              </Text>
              </Flex>

                <motion.div
                  variants={textVariants}
                  style={{ position: 'relative', scrollbarWidth: 'thin' }}
                  initial="collapsed"
                  animate={collapsedState}
                >

                  <Text fontSize="14px" whiteSpace={'normal'}>
                    {summaryIsLoading ? (
                      <Spinner />
                    ) : (
                      summary ? (
                        <>{summary.split('\n').map((line, index) => <>{line}<br /></>)}</>
                      ) :
                        <>
                          No summary available
                        </>
                    )
                    }
                  </Text>

                </motion.div>

              </div>
            ) : null}
          </Stack>
        </CardBody>
        {collapsedState === 'open' ? (
          <CardFooter>
            <HStack width="100%" justifyContent={'flex-end'}>
              <Button
                variant="solid"
                colorScheme="purple"
                onClick={(event) => {
                  openInGraph();
                  toast({
                    title: 'Graph opened',
                    colorScheme: 'purple',
                    description: 'Your Graph has been opened in the sidebar',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                Open Graph
              </Button>
            </HStack>
          </CardFooter>
        ) : null}
      </Card>

      <Modal
        isOpen={isZoteroModalOpen}
        onClose={onZoteroModalClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Zotero Connector</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              You can now use the Zotero connector to cite <br /> {nodeData?.properties.label}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={onZoteroModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </motion.div>
  );
}
