
import {
  Accordion,
  Box,
  Divider,
  Image,
  Spacer,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Home,
  Bookmark,
  Settings,
  LogOut,
  HelpCircle,
  MessageSquare,
  Globe,
  FolderPlus,
  Folder,
  BookOpen,
} from 'react-feather';
import theme from '../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../lib/hooks';
import { useAuth } from 'oidc-react';
import { NewProjectModal } from './NewProjectModal';
import { ProjectsOverviewModal } from './ProjectsOverviewModal';
import { GraphItem } from './SidebarItems/GraphItem';
import { NavAccordionItem } from './SidebarItems/NavAccordionItem';
import { NavAccordionItemChild } from './SidebarItems/NavAccordionItemChild';
import { NavItemButton } from './SidebarItems/NavItem';
import { GraphView } from '../lib/state/ViewTypes';


export default function Sidebar({ isOpen, onToggle }: { isOpen: boolean; onToggle: () => void }) {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const graphViews = useAppSelector((state) => state.viewReducer.graphViews);
  
  const newProjectModalDisclosure = useDisclosure();
  const projectsOverviewModalDisclosure = useDisclosure();
  
  return (
    <Stack
      flexDirection="column"
      minWidth={{
        base: 'min-content',
        md: isOpen ? '30%' : 'min-content',
        xl: isOpen ? '18%' : 'min-content',
      }}
      maxWidth={{
        base: 'min-content',
        md: '30%',
        xl: '18%',
      }}
      height="100%"
      padding={{
        base: '2',
        md: '4',
      }}
      bg={theme.colors.gray[100]}
    >
      <Tooltip
        label="click to expand/minimize"
        bgColor={theme.colors.purple[700]}
        placement="right"
      >
        <Box
          display="flex"
          width="full"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          _hover={{ bg: theme.colors.purple[200], transition: 'background-color 0.2s' }}
          transition="background-color 0.2s"
          borderRadius="md"
          onClick={() => onToggle()}
          tabIndex={1}
        >
          <Image src="/logo512.png" height="16" minWidth="16" userSelect="none" />
        </Box>
      </Tooltip>

      <Stack direction="column">
        <Accordion allowMultiple p={0} gap={2} defaultIndex={[2]} overflow={'visible'}>
          <NavAccordionItem
            title="Home"
            icon={<Home />}
            isOpen={isOpen}
            isActive={location.pathname === '/research'}
            onClick={() => navigate('/research')}
            children={[
              <NavAccordionItemChild
                key="new_project_button"
                text="Create new Project"
                textShort="New Project"
                icon={<FolderPlus />}
                isOpen={isOpen}
                onClick={newProjectModalDisclosure.onOpen}
              />,
              <NavAccordionItemChild
                key="open_project_button"
                text="Open Project"
                textShort="Open Project"
                icon={<Folder />}
                isOpen={isOpen}
                onClick={projectsOverviewModalDisclosure.onOpen}
              />,
            ]}
          />

          <Spacer height={2} />

          <NavAccordionItem
            title="Saved Documents"
            icon={<Bookmark />}
            isOpen={isOpen}
            isActive={location.pathname === '/saved_cases'}
            onClick={() => navigate('/saved_cases')}
            children={[]}
          />

          <Spacer height={2} />

          <NavAccordionItem
            title="Your Graphs"
            icon={<Globe />}
            isOpen={isOpen}
            isActive={location.pathname === '/search'}
            onClick={() => navigate('/graph')}
            children={Object.values(graphViews).map((view: GraphView, i) => {
              return (
                <GraphItem
                  key={`graph_button_${view.id}`}
                  text={view.name}
                  viewId={view.id}
                  icon={<Globe />}
                  isOpen={isOpen}
                  isActive={location.pathname === `/graph/${view.id}`}
                  tabIndex={50 + 2 * i}
                  onClick={() => navigate(`/graph/${view.id}`)}
                />
              );
            })}
          />
        </Accordion>

        <NewProjectModal
          isOpen={newProjectModalDisclosure.isOpen}
          onClose={newProjectModalDisclosure.onClose}
        />

        <ProjectsOverviewModal
          isOpen={projectsOverviewModalDisclosure.isOpen}
          onClose={projectsOverviewModalDisclosure.onClose}
        />
        
      </Stack>

      <Spacer />

      {/* <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      />

      <NavItemButton
        text="Resources"
        tooltip="Find resources about the HuDoc articles"
        isActive={location.pathname === '/feedback'}
        icon={<BookOpen />}
        isOpen={isOpen}
        // textColor={theme.colors.purple[600]}
        textColor="black"
        tabIndex={100}
        onClick={() => window.open('https://nettskjema.no/a/463244')?.focus()}
      />

      <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      /> */}

      <Stack direction="column">
        <NavItemButton
          text="Give us your feedback!"
          tooltip="Feedback"
          isActive={location.pathname === '/feedback'}
          icon={<MessageSquare />}
          isOpen={isOpen}
          textColor={theme.colors.purple[600]}
          tabIndex={100}
          onClick={() => window.open('https://nettskjema.no/a/463244')?.focus()}
        />
        <NavItemButton
          text="How to Meshr"
          tooltip="Learn more about Meshr"
          isActive={location.pathname === '/learn_more'}
          icon={<HelpCircle />}
          isOpen={isOpen}
          textColor="black"
          tabIndex={102}
          onClick={() => navigate('/learn_more')}
        />
        <NavItemButton
          text="Settings"
          tooltip="Settings"
          isActive={location.pathname === '/settings'}
          icon={<Settings />}
          isOpen={isOpen}
          textColor="black"
          tabIndex={104}
          onClick={() =>
            window
              .open(
                `https://auth.meshr.org/realms/${process.env.REACT_APP_AUTH_REALM}/account/`,
                '_blank',
              )
              ?.focus()
          }
        />
        <NavItemButton
          text="Log out"
          tooltip="Log out"
          isActive={location.pathname === '/log_out'}
          icon={<LogOut />}
          isOpen={isOpen}
          textColor="black"
          tabIndex={106}
          onClick={() => auth.signOutRedirect()}
        />
      </Stack>
    </Stack>
  );

}
